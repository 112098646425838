import { Controller } from "@hotwired/stimulus"
import { setValue } from "../helpers/utilities"
import autoComplete from '../../src/auto-complete'

export default class extends Controller {

  static targets = [ "contactsInput", "noresults" ]

  connect() {
    this.configDropdown()
    this.buildAutoComplete()

    this.addressParent = document.querySelector("#" + this.fieldParent)
    this.shippingAddressInput = this.addressParent.querySelector('[data-input="shipping-address"]')
  }

  get fieldParent() {
    return this.data.get('fieldparent')
  }

  configDropdown() {
    $('[data-toggle="dropdown"]').dropdown({
      flip: false,
      popperConfig: {
        placement: 'bottom-end',
        onCreate: function(data){
          $(data.instance.popper).find('input').focus();
        }
      }
    });
  }

  populateLoqateInput(contact) {
    let contactStripName = contact.replace(/^[^,]+, */, '')
    setValue(this.shippingAddressInput, contactStripName)
  }

  checkNoMatches(matches) {
    if (matches.length > 0) {
      this.noresultsTarget.classList.add('d-none')
    } else {
      this.noresultsTarget.classList.remove('d-none')
    }
  }

  populateIndividualFields(term, data) {
    let addressData = this.getDataFromSearchResults(term, data)
    this.findAndFill(addressData)
    // this.element.querySelector("[name*='state_province']")
    this.addressParent.querySelector('[name*="country_id"]').dispatchEvent(new Event('change'))
    this.clearLoqateError()
  }

  clearLoqateError() {
    let parentElement = this.shippingAddressInput.parentElement
    if (parentElement.classList.contains('is-invalid')) {
      parentElement.classList.remove('is-invalid')
    }
  }

  findAndFill(addressData) {
    for (const contactInfo in addressData) {
      let input = this.addressParent.querySelector('[name*="'+ contactInfo +'"]')
      if (input == null) continue
      input.value = String(addressData[contactInfo])
      if (/state/.test(input.name)) {
        input.dataset.autofillVal = String(addressData[contactInfo])
      }
    }
  }

  getDataFromSearchResults(term, data) {
    let addressObject = []
    data.find(function(contact) {
      if (contact.length >= 0 && contact[0] == term) {
        addressObject = contact.pop()
      }
    })
    return addressObject
  }

  arrayforAutocomplete(data) {
    let resultsArray = []
    for (let i=0; i<data.length; i++ )
      resultsArray.push(data[i][0])
    return resultsArray
  }

  buildAutoComplete() {
    let _this = this,
      target = this.contactsInputTarget,
      data = {}

    new autoComplete({
      selector: target,
      minChars: 0,
      cache: false,
      menuClass: 'contactsSearch',
      source: function(term, suggest){
        term = term.toLowerCase()
        let choices = [];
        $.getJSON('/contacts', { q: term }, function(response){
          data = response.data;
          choices = _this.arrayforAutocomplete(data)

          _this.checkNoMatches(choices)
          suggest(choices);
        })
      },
      onSelect: function(e, term, item) {
        _this.populateLoqateInput(term)
        _this.populateIndividualFields(term, data)
      }
    });


  }
}
