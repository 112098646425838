import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ '' ]

  connect() {
    var el = $("#modal-verify-address");
    el.modal({backdrop: 'static', keyboard: false})
      .modal('show')
  }
}