import { Controller } from "@hotwired/stimulus"
import { setValue } from "../helpers/utilities"

export default class extends Controller {

  static targets = [
    "lengthDisplay", "widthDisplay", "heightDisplay",
    "dropdownItem", "measureIllustrations", "packageType",
    "palletWeight"
  ]

  static values = {
    freight: Boolean
  }

  connect() {
    this.addEventHanders()
    this.systemToggles = document.querySelector('#system-toggle')
    this.setMeasureIllustration(this.packageTypeTarget.value)
  }

  addEventHanders() {
    let boxSizes = this.element.getElementsByClassName('dropdown-item')
    Array.prototype.slice.call(boxSizes).forEach(links => {
      links.addEventListener('click', this.handleSelection.bind(this));
    })
  }

  handleSelection(event) {
    event.preventDefault()
    this.inputBoxSize(event.target)
    this.handlePalletWeight(event.target)
    setValue(this.packageTypeTarget, event.target.dataset.boxType)
    this.packageTypeTarget.dispatchEvent(new Event('change'));
  }

  inputBoxSize(target) {
    if (!this.freightValue) {
      let system = this.getSystem()
      setValue(this.lengthDisplayTarget, target.dataset[system + 'Length'])
      setValue(this.widthDisplayTarget, target.dataset[system + 'Width'])
      setValue(this.heightDisplayTarget, target.dataset[system + 'Height'])
    }
    this.dropdownItemTarget.innerHTML = target.innerHTML
    this.setMeasureIllustration(target.dataset['boxType'])
  }

  handlePalletWeight(target) {
    let palletWeightBoxTypes = ['pallet', 'skid']
    let palletWeightShouldBeBlank = !palletWeightBoxTypes.includes(target.dataset.boxType)
    if (this.hasPalletWeightTarget && palletWeightShouldBeBlank) {
      this.palletWeightTargets.forEach(weightTarget => {
        weightTarget.value = ''
      })
    }
  }

  setMeasureIllustration(boxType) {
    // Hide all measure illustrations except for the one that matches the selected box type
    let illustrations = this.measureIllustrationsTarget.children;
    for( var i=0; i < illustrations.length; i++ ) {
      if( illustrations[i].classList.contains('box-type-' + boxType) ){
        illustrations[i].classList.remove('d-none');
        illustrations[i].classList.add('d-block');
      } else {
        illustrations[i].classList.add('d-none');
        illustrations[i].classList.remove('d-block');
      }
    }
  }

  getSystem() {
    let checkedInput = this.systemToggles.querySelector('input:checked')
    return checkedInput.value
  }

}
