import { Controller } from "@hotwired/stimulus"
import { testEmail, testPhone } from "./utilities"

export default class extends Controller {

  static targets = [
    'formValues',
    'radioContainer'
  ]

  connect() {
    const selectedContainer = this.radioContainerTargets.find(el => el.querySelector("input[type='radio']").checked == true)
    this.selectedId = selectedContainer.querySelector("input[type='radio']").id
    this.setFormValues(selectedContainer.querySelector('label'))

  }

  updateAddress(e) {
    if (e.target.htmlFor !== this.selectedId) {
      this.selectedId = e.target.htmlFor
      this.setFormValues(e.target)
      this.radioContainerTargets.forEach(el => el.classList.toggle('border-green'))
    }
  }

  setFormValues(el) {
    const targetSelector = el.dataset.targetTemplate
    const targetTemplate = document.querySelector(targetSelector)
    this.formValuesTarget.innerHTML = targetTemplate.innerHTML
  }

}
