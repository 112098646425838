import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class UserVerification extends Controller {

  connect() {
    this.loadVerificationStatus()
  }

  loadVerificationStatus() {
    $.ajax({
      type: "GET",
      url: `/users/verification_status.html?r=${Math.random()}`,
      success: (response) => {
        $('#replaceable').html(response)
      }
    })
  }

  resendVerificationToken(e) {
    const $a = $(e.target)
    if($a.hasClass("disabled"))
      return false;

    $a.addClass("disabled");
    const original = $a.html()
    const url = e.target.dataset.href;
    $a.html($a.data("disable-with"));

    $.post(url, (response) => {
      $a.html(original)
      $a.removeClass("disabled")
      $('#users_token').focus()
    })
  }

  continue(e) {
    let url = e.target.dataset.href
    if (this.isModal(e)) {
      // User originally triggered verification from the shipment rates view
      $(e.target).parents(".modal").modal('hide')
      window.location.reload();
    } else {
      // User originally triggered verification from the sign up flow
      document.location.href = url;
    }
  }

  skip(e) {
    if (this.isModal(e)) {
      return $(e.target).parents(".modal").modal('hide')
    }
    document.location.href = e.target.dataset.href;
  }

  isModal(e) {
    return $(e.target).parents(".modal").length > 0;
  }
}