import { Controller } from "@hotwired/stimulus"

export default class extends Controller{
  static targets = ["question", "answer","carret"];


  clicked(e) {
    this.toggleAnswer(this.answerTarget)
    this.toggleOpenClose(this.carretTarget)
  }

  toggleOpenClose(button) {
    button.classList.toggle("open")

  }

  toggleAnswer(answer) {
    answer.classList.toggle("shown")
  }

}