import { Controller } from "@hotwired/stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {

  static targets = [ 'removeBtn', 'packageId' ]

  connect() {
    this.packageId = this.data.get('form-id')
  }

  destroy(e) {
    e.preventDefault()
    let _this = this
    anime({
      targets: _this.element,
      opacity: 0,
      scale: 0.95,
      easing: 'easeInOutSine',
      duration: 300,
      complete: function(anim) {
        anim.animatables.forEach(function(elm){
          var child = elm.target
          child.parentNode.removeChild(child);
        })
      }
    });
}

}