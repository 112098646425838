import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.initializeSignWell()
  }

  get signingUrl() {
    return this.data.get('signing-url')
  }

  get redirectTo() {
    return this.data.get('redirect-to')
  }

  get shipmentId() {
    return this.data.get('shipment-id')
  }

  initializeSignWell() {
    var signWellEmbed = new SignWellEmbed({
      url: this.signingUrl,
      containerId: 'signwell-container',
      allowDecline: false,
      allowClose: false,
      redirectionUrl: this.redirectTo,
      allowRedirect: true,
      iframeRedirect: false,
      events: {
        documentLoaded: e => {
          $('#guided-document-app .editor__header__primary__content').remove()
          // $('#guided-document-app .editor__main').style('padding-top: 0;')

        },
        completed: e => {
          //document successfully signed/completed
          this.postCreation()
        },
        closed: e => {
          //SignWell iFrame closed
          window.location = this.redirectTo
        }
      }
    })

    signWellEmbed.open()
  }

  postCreation(e) {
    $.post(`/freight/shipments/${this.shipmentId}/signature`)
      .done(() => {
        return true
      })
      .fail(()=> {
        // TODO: How to handle failure?
        return false
      })
  }

}