import { Controller } from "@hotwired/stimulus"
import lottie from 'lottie-web'
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {

  static targets = ['loginForm', 'allowPropagation', 'headerTitle', 'container', 'pageOverlay' ]

  connect() {
    this.triggerAnimation();
  }

  stopPropagation(e) {
    let allowed = false
    this.allowPropagationTargets.forEach((target) => {
      allowed = allowed || (target == e.target) || target.contains(e.target)
    });

    if (allowed) {
      return true
    } else {
      e.stopPropagation();
    }
  }

  triggerAnimation() {

    if( this.containerTarget.hasAttribute("data-playAnim") ){
      // If we want to display the animation, do a bunch of stuff

      this.containerTarget.style.marginBottom = '100px'; // Set the container margin to push the page contents down
      this.pageOverlayTarget.style.opacity = '1'; // Make the page overlay visible
      this.headerTitleTarget.style.transform = 'translateX(100px)'; // Move the title to the right
      this.headerTitleTarget.style.opacity = '0'; // Make the title invisible

      setTimeout(() => { // Wait a bit before revealing the page

        //---------- Title ----------//
        anime({ // Animate title transform and opacity back to default
          targets: this.headerTitleTarget,
          loop: false,
          easing: 'cubicBezier(0,.5,.5,1)',
          delay: 500,
          duration: 667,
          translateX: '0',
          opacity: '1'
        });

        //---------- Container ----------//
        anime({ // Animate container margin back to default
          targets: this.containerTarget,
          loop: false,
          easing: 'cubicBezier(0,.5,.5,1)',
          duration: 1200,
          marginBottom: '0'
        });

        //---------- Page Overlay ----------//
        anime({ // Animate overlay opacity back to zero
          targets: this.pageOverlayTarget,
          loop: false,
          easing: 'cubicBezier(0,.5,.5,1)',
          duration: 1200,
          opacity: '0'
        });
      }, 500);

    }

  }
}
