// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
Rails.start()

require("@rails/activestorage").start()
require("channels")

global.LocalTime = require("local-time")
global.LocalTime.start()

global.jstz = require('jstimezonedetect');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const imagePath = (name) => images(name, true)
import '@hotwired/turbo-rails'
import 'bootstrap/dist/js/bootstrap'
import 'nodelist-foreach-polyfill'
import 'ie-array-find-polyfill'
import "controllers"

global.$ = jQuery;

// styles
const images = require.context('../images', true)
const animData = require.context('../animations', true)

import 'stylesheets/bootstrap'
import 'pikaday/scss/pikaday.scss'
import 'stylesheets/application_webpack'

import "trix"
import "@rails/actiontext"


// stop Turbo from remembering scroll position and reset to top
document.addEventListener('turbo:load', function() {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  } else {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
})

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// Monkey patch Turbolinks to render 403, 404 & 500 normally
// See https://github.com/turbolinks/turbolinks/issues/179
// window.Turbolinks.HttpRequest.prototype.requestLoaded = function() {

//   return this.endRequest(function() {
//     var code = this.xhr.status;
//     if (200 <= code && code < 300 ||
//         code === 403 || code === 404 || code === 500) {
//       this.delegate.requestCompletedWithResponse(
//           this.xhr.responseText,
//           this.xhr.getResponseHeader("Turbolinks-Location"));
//     } else {
//       this.failed = true;
//       this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
//     }
//   }.bind(this));
// };