import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.flatpickr = flatpickr('[data-flatpickr = "range"]', {
      mode: "range",
      dateFormat: "Y-m-d",
      onClose: (selectedDates, _dateStr, _instance)=> {
        this.getResults(selectedDates);
      },
      altInput: true,
      altFormat: 'm/d/Y',
      defaultDate: this.getDefaultDates(),
    });
  }

  buildDateString(date) {
    var year = String(date.getFullYear());
    var month = String(date.getMonth() + 1);
    if (month.length == 1) {
      month = '0' + month
    }
    var day = String(date.getDate());
    if (day.length == 1) {
      day = '0' + day
    }

    return `${year}-${month}-${day}`
  }

  convertDateToString(date) {
    if (date) {
      return this.buildDateString(date);
    } else {
      return ''
    }
  }

  getResults(selectedDates) {
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("start_on", this.convertDateToString(selectedDates[0]));
      searchParams.set("end_on", this.convertDateToString(selectedDates[1]));
      window.location.search = searchParams.toString();
    }
  }

  getDefaultDates() {
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search);
      var startOn = searchParams.get('start_on')
      var endOn = searchParams.get('end_on')
      if ((startOn && startOn != '') && (endOn && endOn != '')) {
        return [startOn, endOn]
      }
    }
  }

  disconnect() {
    this.flatpickr.destroy();
  }


}