import { Controller } from "@hotwired/stimulus"
import lottie from 'lottie-web'

export default class extends Controller {

  static targets = [ "animation" ]

  connect() {
    this.loadingAnimation()
  }


  loadingAnimation() {
    lottie.loadAnimation({
      container: this.animationTarget,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.element.dataset.animPath,
    });
  }

}
