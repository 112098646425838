import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  submitForm(e) {
    let form = e.target.closest('form')
    Rails.fire(form, "submit")
  }
}
