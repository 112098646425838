import { Controller } from "@hotwired/stimulus"
import { testEmail, testPhone } from "./utilities"
import { compareValues } from "../helpers/utilities"

export default class extends Controller {

  static targets = [ "form", "required", "submit" ]

  initialize(){
  }

  connect() {
    this.enableValidation()
    this.passwordInputs = this.element.querySelectorAll('input[type="password"]')
    this.validateForm()
  }

  validateForm(event) {
    var checks = [
      this.validateRequiredFields(),
      ...((this.passwordInputs || []).length > 1 ? [this.comparePasswords()] : [])
    ]

    if (checks.indexOf(false) === -1) {
      this.valid = true
      this.hasSubmitTarget && this.enableSubmit(!this.valid)
    } else {
      this.valid = false
      this.enableSubmit(this.valid)
    }
  }

  rebindEvents() {
    this.connect()
  }

  validateRequiredFields() {
    let valid
    for(let i=0; i<this.requiredTargets.length; i++){
      let el = this.requiredTargets[i]
      if (el.type == 'checkbox') {
        valid = el.checked == true;
      } else if (el.type == 'tel') {
        valid = testPhone(el.value);
      } else if (el.type == 'email') {
        valid = testEmail(el.value);
      } else {
        // use Constraint validation API for el.validity.valid
        valid = typeof el.value !== 'undefined' && el.value.length > 0 && el.validity.valid != false
      }
      if(!valid){
        break
      }
    }
    return valid
  }

  comparePasswords() {
    let valueMatch = this.passwordInputs[0].value == this.passwordInputs[1].value
    return valueMatch
  }

  enableSubmit(value) {
    this.submitTarget.classList.toggle('disabled', value)
    this.submitTarget.disabled = value
  }

  enableValidation() {
    let _this = this
    this.requiredTargets.forEach((el, i) => {
      el.oninput = function() {
        _this.validateForm()
      }
    })
  }

  submitForm(){
    if(this.valid){
      this.formTarget.submit()
    }
  }

  get valid(){
    return this.data.get('valid')
  }

  set valid(value) {
    this.data.set('valid', value)
  }
}
