import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

const requiresStateSelector = (countryAbbrev) => ['US', 'MX', 'CA'].includes(countryAbbrev)
export default class extends Controller {

  static targets = [
    'stateProvinceSelect',
    'stateProvinceContainer',
    'countrySelect'
  ]

  getStatesForCountry(countryId) {
    Rails.ajax({
      type: "GET",
      url: "/state_provinces.json",
      data: "country_id=" + countryId,
      success: (data) => {
        this.refreshStateProvinceOptions(data)
      }
    })
  }

  updateStateProvince(e) {
    const selected = e.target
    const requiredInputTargetValue = 'forms-RequiredTarget'
    const selectedAbbreviation = selected.options[selected.selectedIndex].dataset.abbreviation

    if (requiresStateSelector(selectedAbbreviation)) {
      this.stateProvinceContainerTarget.classList.remove('hidden')
      this.stateProvinceSelectTarget.required = true
      this.stateProvinceSelectTarget.dataset[requiredInputTargetValue] = 'required'
      this.getStatesForCountry(e.target.value)
    } else {
      this.stateProvinceContainerTarget.classList.add('hidden')
      this.stateProvinceSelectTarget.required = false
      this.stateProvinceSelectTarget.value = ''
      delete this.stateProvinceSelectTarget.dataset[requiredInputTargetValue]
    }

    window.dispatchEvent(new Event('required-form-swapped'))
  }

  refreshStateProvinceOptions(data) {
    const selected = this.stateProvinceSelectTarget.dataset.autofillVal
    const options = data.map((opt) => {
      const optionEl = document.createElement('option')
      optionEl.value = opt[1]
      optionEl.innerText = opt[0]

      if(typeof selected !== 'undefined' && opt.includes(selected)) {
        optionEl.setAttribute('selected', true)
      }

      return optionEl.outerHTML
    })

    this.stateProvinceSelectTarget.innerHTML = options.join(' \n');;
    window.dispatchEvent(new Event('required-form-swapped'))
  }

}