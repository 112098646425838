import { Controller } from "@hotwired/stimulus"
import { testEmail, testPhone } from "./utilities"
import { classToggleForIE } from "../helpers/utilitiesIE"

export default class extends Controller {

  static targets = [ "emailField", "passwordError", "phoneField" ]

  validateEmail(event) {
    let emailValid = testEmail(event.currentTarget.value)
    classToggleForIE(this.emailFieldTarget, 'is-invalid', !emailValid)
  }

  validatePassword(event) {
    let passLength = event.currentTarget.value.length < 8
    classToggleForIE(event.currentTarget.parentElement, 'is-invalid', passLength)
    classToggleForIE(this.passwordErrorTarget, 'show', passLength)
  }

  validatePhone(event) {
    if (event.currentTarget.value != '') {
      let phoneValid = testPhone(event.currentTarget.value)
      classToggleForIE(this.phoneFieldTarget, 'is-invalid', !phoneValid)
    }
  }
}
