import { Controller } from "@hotwired/stimulus"
import { classToggleForIE } from "../helpers/utilitiesIE";
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {
  static targets = ["topic", "topicFaq", "menu"];

  initialize() {
    this.setTopicFromURL();
  }

  click(event) {
    event.preventDefault();
    this.selected = event.currentTarget.dataset.id;
  }

  menuClick(event) {
    event.preventDefault();
    this.showHideMenu();
  }

  showHideMenu() {
    this.menuTarget.classList.toggle("opened");
  }

  showCurrentTopic(topicID) {
    let topicTarget = this.selected;
    if( topicID ){
      topicTarget = topicID;
    }

    this.topicTargets.forEach((top) => {
      classToggleForIE(top, "topic--current", "topic" + topicTarget == top.id);
    });
    this.topicFaqTargets.forEach((top) => {
      classToggleForIE(top, "topic--current", topicTarget == top.id);
    });
    this.menuTarget.classList.remove("opened");

    this.setURLTopic(topicTarget); // Set the URL parameter to the new topic ID

    if (window.scrollY > 50) {
      this.scrollToTop()
    }
  }

  setTopicFromURL() {
    // If the URL contains the "topic" parameter, set the topic to that value. Otherwise, just set it to the first topic.
    let url = new URL(window.location.href);
    let params = url.searchParams.get('topic');

    if (url.pathname.includes('blog')) return;

    if ( params ) {
      this.showCurrentTopic(params);
    } else {
      this.showCurrentTopic();
    }
  }

  setURLTopic(topicID) {
    // Set a new value for the "topic" parameter in the URL

    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set("topic", topicID);
    history.replaceState(null, null, "?"+queryParams.toString());
  }

  get selected() {
    return parseInt(this.data.get("selected"));
  }

  set selected(value) {
    this.data.set("selected", value);
    this.showCurrentTopic();
  }

  scrollToTop() {
    anime({
      targets: [document.documentElement, document.body],
      scrollTop: 0,
      easing: 'easeInOutSine',
      duration: 150,
    });
  }
}
