import { Controller } from "@hotwired/stimulus"
import jstz from 'jstimezonedetect'

export default class extends Controller {

  static targets = ['flash']

  connect() {
    this.addCookie()
  }

  addCookie() {
    var tzName = jstz.determine().name()
    document.cookie = "browser.tzoffset=" + tzName
  }
}