import { Controller } from "@hotwired/stimulus"
import { disableSubmit, enableSubmit, checkForEmpty } from "../helpers/utilities"
import Inputmask from "inputmask"

export default class extends Controller {

  static targets = [
    "numberInput", "declaredValueInput", "errorMsg", "nonZeroInput"
  ]

  connect() {
    this.numberInputMasking()
    this.currencyMasking()
    this.toggleRequired()
    this.validateMinCurrency()

    this.submitButton = document.querySelector('input[type="submit"]')
  }

  get minDeclaredValue() {
    return Number(this.data.get('minDeclaredValue'))
  }

  get maxDeclaredValue() {
    let data = JSON.parse(this.data.get('maxDeclaredValues'))
    return data[this.currencyId]
  }

  get currencyId() {
    return Number(this.data.get('currencyId'))
  }

  numberInputMasking() {
    let inputs = this.numberInputTargets
    const im = new Inputmask("9{1,4}[.9]", { "placeholder": ""});

    for (const input of inputs) {
      im.mask(input);
    }
  }



  currencyMasking() {
    if (this.hasDeclaredValueInputTarget) {
      let inputs = this.declaredValueInputTargets

      const im = new Inputmask({
          'alias': 'currency',
          'groupSeparator': ',',
          'placeholder': '0',
          'autoUnmask': true,
          'removeMaskOnSubmit': true,
          'clearMaskOnLostFocus': true,
          'digits': 0,
      });

      for (const input of inputs) {
        im.mask(input);
      }
    }
  }

  validateMaxCurrency(event) {
    let unmaskedValue = event.currentTarget.value;
    let maxValue = this.maxDeclaredValue
    const parent = event.currentTarget.parentElement;

    if (parseFloat(unmaskedValue) > maxValue) {
      parent.classList.add('is-invalid')
      this.hasErrorMsgTarget && this.errorMsgTarget.classList.add('d-block')
      disableSubmit(this.submitButton)
    } else {
      parent.classList.remove('is-invalid')
      this.hasErrorMsgTarget && this.errorMsgTarget.classList.remove('d-block')
      enableSubmit(this.submitButton)
    }
  }

  validateMinCurrency() {
    if (this.hasDeclaredValueInputTarget) {
      if (Number(this.declaredValueInputTarget.value) < this.minDeclaredValue) {
        this.declaredValueInputTarget.setCustomValidity('Declared value must be $1 or greater.');
      } else {
        this.declaredValueInputTarget.setCustomValidity('');
      }
    }
  }

  toggleRequired() {
    let weightTargets = Array.from(this.element.querySelectorAll("[data-weight-target='true']"))

    if (weightTargets.some(checkForEmpty)) {
      weightTargets.forEach((el, i) => {
        el.removeAttribute('required')
      })
    } else {
      weightTargets.forEach((el, i) => {
        el.setAttribute('required', 'required')
      })
    }
  }

}
