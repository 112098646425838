import { Controller } from "@hotwired/stimulus"

const DEPT_OF_STATE = 'department_of_state'

export default class extends Controller {

  static targets = [
    'requiredStateFieldsContainer',
    'extrasTemplate',
    'dscTypeSelect'
  ]
  connect() {
    this.extraFieldsRequired = this.dscTypeSelectTarget.value == DEPT_OF_STATE
    this.updateFieldVisibility()
  }

  checkRequiredFieldChange() {
    const newVal = this.dscTypeSelectTarget.value == DEPT_OF_STATE

    this.extraFieldsRequired = newVal
    this.updateFieldVisibility()
  }

  updateFieldVisibility() {
    const classListFunc = this.extraFieldsRequired ? 'remove' : 'add'
    const inputs = this.requiredStateFieldsContainerTarget.querySelectorAll('.form-control')

    this.requiredStateFieldsContainerTarget.classList[classListFunc]('d-none')
    inputs.forEach(inp => inp.required = this.extraFieldsRequired)
  }

}
