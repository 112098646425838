import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = [ "countryInput", "urbanizationCodeInput" ]

  connect() {}

  setCountry() {
    this.checkUrbanization()
    this.updateDestinationCountryOptions()
  }

  checkUrbanization() {
    if( this.countryId() == 117 ){
      this.urbanizationCodeInputTarget.classList.remove('hidden')
    } else {
      this.urbanizationCodeInputTarget.classList.add('hidden')
    }
  }

  updateDestinationCountryOptions() {
    if (this.data.get('shippablePartyType') == 'origin') {
      Rails.ajax({
        type: "GET",
        url: "/destination_country_options",
        data: "origin_country_id=" + this.countryId()
      })
    }
  }

  countryId() {
    return this.countryInputTarget.value
  }
}
