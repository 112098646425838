import { Controller } from "@hotwired/stimulus"
import { loqateConfig } from '../../src/loqate.js.erb';

export default class extends Controller {

  static targets = [ "addressInput", "urbanizationCodeInput", 'switchInputLink' ]

  connect() {
    this.loqateInit()
    this.allAddressFields = this.element.querySelectorAll("[name*='address']")
    this.contactHiddenFields = this.element.querySelectorAll("[data-target='user-contact']")
    this.currentKeyCode = null
    this.addressInputTarget.addEventListener('keydown', (e) => this.currentKeyCode = e.keyCode)
  }

  loqateInit() {
    let addressName = this.addressInputTarget.name

    if (addressName.indexOf('origin') > -1) {
      this.newAddress(loqateConfig.fields_origin, loqateConfig.options)
    } else {
      this.newAddress(loqateConfig.fields_destination, loqateConfig.options)
    }

    this.hasPickupAddressTarget() && this.newAddress(loqateConfig.fields_pickup, loqateConfig.options)
  }

  hasPickupAddressTarget() {
    return this.element.querySelector("[data-pickup-address-target='true']") != null
  }

  newAddress(fields, options) {
    options.countries.codesList = this.data.get('countries')
    this.addressControl = new pca.Address(fields, options)
    let _this = this

    this.addressControl.listen("populate", function(address) {
      _this.clearError()
      _this.updateCountrySelect(address)
      _this.formatUnitNumber(address, fields)
      _this.formatPostalCode(address, fields)
      if(_this.hasSwitchInputLinkTarget) {
        _this.switchInputLinkTarget && _this.switchInputLinkTarget.click()
      }
    });

  }

  updateCountrySelect(address) {
    let field = this.element.querySelector("select[name*='country_id']")
    const currentSelected = field.options[field.selectedIndex].dataset.abbreviation
    const opts = Array.prototype.slice.call(field.options)
    const newIndex = opts.findIndex(el => el.dataset.abbreviation == address.CountryIso2)
    this.element.querySelector("[name*='state_province']").dataset.autofillVal = address.Province
    field.selectedIndex = newIndex

    // The pickup request form has a hidden field to send in the country code
    // due tothe select input bieng disabled. We need to fill in the country_id
    // for that hidden input. This can be removed when international pickup is added.
    let hiddenField = this.element.querySelector("input[name*='country_id']")

    if (hiddenField) {
      hiddenField.value = opts[newIndex].value
    }
    field.dispatchEvent(new Event('change'))
  }

  formatUnitNumber(address, fields) {
    let addressString = address.Line1
    let unitNumberString = address.Line2
    // let unitNumberString = address.SubBuilding
    let addressLine1Input = document.getElementById(fields[1].element)

    if( unitNumberString.length ){
      addressLine1Input.value = addressString.split(unitNumberString)[0]
    }
  }

  formatPostalCode(address, fields) {
    let postalCodeString = address.PostalCode
    let postalCodeInput = document.getElementById(fields[5].element)
    let urbanizationCodeInput = document.getElementById(fields[6].element)

    if( address.CountryName == "Puerto Rico" ){
      this.urbanizationCodeInputTarget.classList.remove('hidden')
      postalCodeInput.value = postalCodeString.split('-')[0]
      urbanizationCodeInput.value = postalCodeString.split('-')[1]
    } else {
      if(this.hasUrbanizationCodeInputTarget) {
        this.urbanizationCodeInputTarget.classList.add('hidden')
      }
      postalCodeInput.value = postalCodeString
    }
  }

  clearError() {
    let parent = this.addressInputTarget.parentElement
    parent.classList.remove('is-invalid')
  }

  addError() {
    let parent = this.addressInputTarget.parentElement
    parent.classList.add('is-invalid')
  }

  checkInput(event) {
    // lose focus()
    if (this.currentKeyCode == 13) return

    if (event.currentTarget.value != '' && !this.isPrimaryAddress(event)) {
      event.currentTarget.value = ''
      this.addError()
      this.clearPopulatedValues(this.allAddressFields)
      this.clearPopulatedValues(this.contactHiddenFields)
    } else {
      this.clearError()
    }
  }

  isPrimaryAddress(event) {
    let element = event.currentTarget
    return element.value == element.defaultValue
  }

  clearPopulatedValues(inputs) {
    inputs.forEach(element => {
      if (element.tagName.toLowerCase() == 'select') {
        element.selectedIndex = 0
      } else {
        element.value = ""
      }
    })
  }

  reinit() {
    let addressName = this.addressInputTarget.name
    if (addressName.indexOf('destination') > -1) {
      this.addressControl.destroy()
      this.loqateInit()
    }
  }

}