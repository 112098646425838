var RequestPoller = (function () {
  const requestInterval = 5000;

  function _sendRequest(path, data, callback) {
    let req = _setRequest(path, {}, 'GET', 'json');
    return req.done(callback);
  }

  function _setRequest(path, params, method, dataType) {
    return $.ajax({
      url: path,
      type: method,
      dataType: dataType,
      data: params
    });
  }

  const Observer = function(path, data, interval) {
    this.path = path;
    this.params = data;
    this.pollInterval = typeof interval !== 'undefined' && interval || requestInterval 
  }

  Observer.prototype = {
    isPolling: true,
    stop: function() {
      this.isPolling = false;
    },
    pollCount: 0,
    subscribe: function(callback) {
      if (this.isPolling) {
        this.pollCount > 0 && _sendRequest(this.path, this.data).done(callback.bind(this));
        this.pollCount += 1
        setTimeout(this.subscribe.bind(this, callback), this.pollInterval)
      }

    }
  }

  

  return Observer;
}());


export default RequestPoller