import { Controller } from "@hotwired/stimulus"
import { classToggleForIE } from "../helpers/utilitiesIE"
import { disableSubmit, enableSubmit} from "../helpers/utilities"

export default class extends Controller {

  static targets = [
    "timeError",
    "availableAt",
    "availableTill"
  ]

  initialize() {
    this.submitButton = document.querySelector('input[type="submit"]')
  }

  connect() {
    this.validateTimes()
  }

  validateTimes() {
    let validTimeRange = this.compareAvailableTimes()
    classToggleForIE(this.timeErrorTarget.parentElement, 'is-invalid', validTimeRange)
    this.manageSubmitButton(validTimeRange)
  }

  manageSubmitButton(validTimeRange) {
    if (validTimeRange) {
      disableSubmit(this.submitButton)
    } else {
      enableSubmit(this.submitButton)
    }
  }

  compareAvailableTimes() {
    return this.availableAtTarget.value >= this.availableTillTarget.value
  }

}