import { Controller } from "@hotwired/stimulus"
import { animeOut, animeIn} from "./anime_actions"
import { camelize, parseBrackets, changeClass } from "../helpers/utilities"

export default class extends Controller {

  static targets = [ "" ]

  connect() {
    this.noResults = document.querySelector('#noResults')
    this.listenForFilters()
  }

  checkForNoResults() {
    if (this.viewableCards().length) {
      this.noResults.classList.add('d-none')
    }else {
      this.noResults.classList.remove('d-none')
    }
  }

  filterCards() {
    let count = this.filterCount();

    if (count > 0) {
      this.findCards()
    } else if (count == 0) {
      let content = this.allCards()
      animeIn(content);
    }
    this.checkForNoResults()
  }

  loadWeekendDelivery() {
    let form = document.getElementById("select-shipping")
    form.submit()
  }

  findCards() {
    let filters = this.returnCheckedFilters()
    let cards = this.allCards()
    let filterNamesArray = []
    filters.forEach(function(elem) {
      let string = camelize(parseBrackets(elem.name))
      let newFilter = string
      filterNamesArray.push(newFilter)
    })

    this.displayCards(filterNamesArray, cards)
  }

  displayCards(filterNamesArray, cards) {
    cards.forEach(function(elem, currentIndex, listObj) {
      const hasData = function(filter) { return elem.dataset[filter] == 'true' }
      // TODO: something to check for ship date != next business day

      let event = new Event('filterCards')
      if (filterNamesArray.every(hasData)) {
        animeIn(elem, event)
      } else {
        animeOut(elem, event)
      }
    })
  }

  filterCount() {
    let filters = this.returnCheckedFilters()
    return filters.length
  }

  returnCheckedFilters() {
    return this.element.querySelectorAll('input:checked[data-filtertype="noncarrier"]')
  }

  allCards() {
    return document.querySelectorAll('.card--carrier')
  }

  viewableCards() {
    return document.querySelectorAll('.carrier-column:not(.d-none) .card--carrier:not(.d-none)')
  }

  listenForFilters() {
    window.addEventListener('filterCards', ()=> {
      this.checkForNoResults();
    });
  }

}