import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "fieldset", "input" ]

  connect() {
    this.evaluatePackageType()
  }

  evaluatePackageType() {
    if (this.hasFieldsetTarget) {
      let packageTypeVal = this.inputTarget.value
      let palletTypes = ['pallet', 'skid']
      let hiddenClass = 'd-none'

      if (palletTypes.includes(packageTypeVal)) {
        this.fieldsetTarget.classList.remove(hiddenClass)
      } else {
        this.fieldsetTarget.classList.add(hiddenClass)
      }
    }
  }

}
