import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "length", "width", "height", "primaryWeight", "extraWeight",
    "dimensionsUnit", "weightUnit", "freightCta"
  ]

  connect() {
    this.listenForRemovedPackage()
  }

  listenForRemovedPackage() {
    window.addEventListener('packageRemoved', ()=> {
      this.evaluatePackage()
    })
  }

  evaluatePackage() {
    if (this.hasFreightCtaTarget) {
      $.post('/freight/package_evaluations',
        {
          length: this.lengthTarget.value,
          width: this.widthTarget.value,
          height: this.heightTarget.value,
          dimensions_unit: this.dimensionsUnitTarget.value,
          primary_weight: this.primaryWeightTarget.value,
          extra_weight: this.extraWeightTarget.value,
          weight_unit: this.weightUnitTarget.value
        },
        (data) => {
          this.toggleLinks(data.requires_freight)
        }
      )
    }
  }

  toggleLinks(requiresFreight) {
    let hiddenClass = 'd-none'
    let disabledClass = 'disabled'
    let mainFormActions = [
      document.getElementById('formSubmit'),
      document.getElementById('addPackage')
    ]

    if (requiresFreight) {
      this.freightCtaTarget.classList.remove(hiddenClass)
      mainFormActions.forEach( (elm) => {
        elm.classList.add(disabledClass)
        elm.disabled = true
      })
    } else {
      this.freightCtaTarget.classList.add(hiddenClass)
      mainFormActions.forEach( (elm) => {
        elm.classList.remove(disabledClass)
        elm.disabled = false
      })
    }
  }

}
