export const loqateConfig = {
  fields_origin: [
    {element: "origin_string", field: "Label", mode: pca.fieldMode.DEFAULT},
    {element: "rate_request_origin_attributes_address_attributes_street", field: "FormattedLine1", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_origin_attributes_address_attributes_street_extras", field: "FormattedLine2", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_origin_attributes_address_attributes_city", field: "City", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_origin_attributes_address_attributes_state_province", field: "Province", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_origin_attributes_address_attributes_postal_code", field: "", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_origin_attributes_address_attributes_urbanization_code", field: "", mode: pca.fieldMode.POPULATE}
  ],
  fields_destination: [
    {element: "destination_string", field: "Label", mode: pca.fieldMode.DEFAULT },
    {element: "rate_request_destination_attributes_address_attributes_street", field: "FormattedLine1", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_destination_attributes_address_attributes_street_extras", field: "FormattedLine2", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_destination_attributes_address_attributes_city", field: "City", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_destination_attributes_address_attributes_state_province", field: "Province", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_destination_attributes_address_attributes_postal_code", field: "", mode: pca.fieldMode.POPULATE},
    {element: "rate_request_destination_attributes_address_attributes_urbanization_code", field: "", mode: pca.fieldMode.POPULATE}
  ],
  fields_pickup: [
    {element: "pickup_string", field: "Label", mode: pca.fieldMode.DEFAULT },
    {element: "pickup_request_address_attributes_street", field: "FormattedLine1", mode: pca.fieldMode.POPULATE},
    {element: "pickup_request_address_attributes_street_extras", field: "FormattedLine2", mode: pca.fieldMode.POPULATE},
    {element: "pickup_request_address_attributes_city", field: "City", mode: pca.fieldMode.POPULATE},
    {element: "pickup_request_address_attributes_state_province", field: "Province", mode: pca.fieldMode.POPULATE},
    {element: "pickup_request_address_attributes_postal_code", field: "", mode: pca.fieldMode.POPULATE},
    {element: "pickup_request_address_attributes_urbanization_code", field: "", mode: pca.fieldMode.POPULATE}
  ],
  options: {
    key: 'ED99-GN92-ZT93-AB95',
    suppressAutocomplete: false,
    culture: "en_US",
    setCountryByIP: true,
    orderingMode: pca.orderingMode.LOCATION,
    search: { origin: '' },
    countries: {
      codesList: "USA",
      fallbackCode: "USA"
    }
  }
}