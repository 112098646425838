import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "form" ]

  initialize(){
  }

  formInvalid() {
    const invalids = Array.prototype.slice.call(document.body.querySelectorAll('.is-invalid'))
    return invalids.filter(el => el.querySelectorAll("[required]").length > 0).length > 0
  }

  submit(e) {
    let key = this.data.get('key')
    let action = this.data.get('action')
    let controller = this
    if(this.formTarget.checkValidity() === false || this.formInvalid()) return false
    e.preventDefault()
    grecaptcha.ready(()=> {
      grecaptcha.execute(key, {action: action}).then((token)=> {
        document.getElementById("recaptcha_token").value = token;
        controller.formTarget.submit();
      });
    });
  }
}
