import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = [ 'checkbox', 'badge' ]

  getParamValues() {
    let vals = []
    this.checkboxTargets.forEach((target)=> {
      if (target.checked) {
        vals.push(`${target.dataset.param}[]=${target.value}`)
      }
    })
    return vals.join('&')
  }

  makeRequest() {
    Rails.ajax({
      url: "/shipments?ajax=true&" + this.getParamValues(),
      type: "get",
      success: function(data) {},
      error: function(data) {}
    })
  }

  filter(e) {
    this.uncheckFilter(e.currentTarget)
  }

  uncheckFilter(badge) {
    var elm = this.checkboxTargets.filter(target =>
      (target.dataset.param == badge.dataset.param) && (target.value == badge.dataset.key)
    )[0]
    elm.checked = false
    this.makeRequest()
  }

}
