import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  get className() {
    return this.data.get('classname')
  }

  get container() {
    return this.data.get('container')
  }

  toggleClass() {
    let container = this.buildContainerSelector()
    let addedClass = String(this.className);
    container.classList.toggle(addedClass)
  }

  buildContainerSelector() {
    let getContainer = this.container != null ? this.container : 'body';
    return document.querySelector(getContainer)
  }
}
