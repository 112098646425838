import { Controller } from "@hotwired/stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {

  static targets = [ "animation" ]

  connect() {
    this.loadingAnimation()
  }

  loadingAnimation() {
    anime({
      targets: this.animationTarget,
      loop: true,
      rotate: {
        value: '+=2turn',
        duration: 3200,
        easing: 'easeInOutSine'
      }
    });
  }


}