import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["sessionId"]

  connect() {
    this.initSeon()
  }

  initSeon() {
    seon.config({
      host: 'deviceinf.com',
      session_id: 'test',
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: (message) => {
        this.writeBase64Session()
      },
      onError: (message) => {
        console.log("error", message);
      }
    })
  }

  writeBase64Session() {
    seon.getBase64Session((data) => {
      if (data) {
        this.sessionIdTarget.value = data
      } else {
        console.log("Failed to retrieve session data.");
      }
    });
  }

}
