import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['resendLink', 'error', 'pending']

  connect() {
    this.sends = 0
    this.hideResendLink()
  }

  get timeout() {
    return this.data.get('timeout')
  }

  hideResendLink(e) {
    this.trackSend()
    this.toggleTarget(this.resendLinkTarget, true)
    this.toggleTarget(this.pendingTarget, false)
    this.startTimeout()
  }

  toggleTarget(target, condition) {
    target.classList.toggle('hidden', condition)
  }

  trackSend() {
    this.sends += 1
  }

  startTimeout() {
    const timeout = Number(this.timeout)

    setTimeout(() => {
      if (this.sends >= 5){
        this.toggleTarget(this.errorTarget, false)
      } else{
        this.toggleTarget(this.resendLinkTarget, false)
      }
      this.toggleTarget(this.pendingTarget, true)
    }, timeout);
  }




}
