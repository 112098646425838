import { Controller } from "@hotwired/stimulus"
import { classToggleForIE } from "../helpers/utilitiesIE";

const MAX_ITEMS = 10

export default class extends Controller {
  static targets = [
    'items',
    'contentItem',
    'addButton',
    'removeButtons',
    'contentTemplate',
    'contentError'
  ]

  connect() {
    this.validate()
  }

  get errorMsg() {
    return this.data.get('error-msg')
  }

  validate(length) {
    let itemLength = length == null ? this.getItemsLength() : length

    this.isValid = (itemLength >= 1) && (itemLength <= MAX_ITEMS)
    this.toggleError(itemLength, this.errorMsg)
    this.disableDeleteButtonIfNeeded(itemLength)
  }

  disableDeleteButtonIfNeeded(length) {
    let itemsLengths = length

    this.removeButtonsTargets.forEach(elem => {
      classToggleForIE(elem, 'd-none', itemsLengths <= 1)
    })
  }

  getItemsLength() {
    // IE11 includes <TEMPLATE></TEMPLATE> content in selectors
    let itemsArray = []
    this.itemsTargets.forEach(item => {
      if (item.parentElement.nodeName != "TEMPLATE" && item.parentElement.nodeName != "SCRIPT") {
        itemsArray.push(item)
      }
    })
    return itemsArray.length
  }

  addContent(e) {
    e.preventDefault()
    if (this.getItemsLength() != MAX_ITEMS) {
      this.buildNewTemplate()
      this.validate()
      this.updateWeightSystemEvent()
      return
    }
  }

  buildNewTemplate() {
    const template = this.contentTemplateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.addButtonTarget.insertAdjacentHTML('beforebegin', template)
  }

  toggleError(listLength, msg) {
    if (listLength >= MAX_ITEMS) {
      this.contentErrorTarget.firstElementChild.innerHTML = msg
      this.addButtonTarget.classList.add('hidden')
      this.contentErrorTarget.classList.add('show')
      return
    }
    this.addButtonTarget.classList.remove('hidden')
    this.contentErrorTarget.classList.remove('show')

  }

  contentRemoved(e) {
    let updateLength =  this.getItemsLength() - 1
    window.dispatchEvent(new Event('packageRemoved'))
    this.validate(updateLength)
  }

  updateWeightSystemEvent() {
    // dispatch event to dimensions_controller this.updateAllDisplay()
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("package-added", true, true, null)
    window.dispatchEvent(event)
  }
}