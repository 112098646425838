import anime from "animejs/lib/anime.es.js"

export function animeOut(content, event) {
  anime({
    targets: content,
    opacity: 0,
    scale: 0.9,
    easing: 'easeInOutSine',
    duration: 300,
    complete: function(anim) {
      anim.animatables.forEach(function(elm){
        elm.target.classList.add('d-none');
        if(event) {
          window.dispatchEvent(event);
        }
      })
    }
  });
}

export function animeIn(content, event) {
  anime({
    targets: content,
    opacity: 1,
    scale: 1,
    delay: 300,
    easing: 'easeInOutSine',
    duration: 300,
    begin: function(anim) {
      anim.animatables.forEach(function(elm){
        elm.target.classList.remove('d-none')
      })
    },
    complete: function() {
      if(event) {
        window.dispatchEvent(event);
      }
    }
  });
}

export function animeCollapse(content) {
  anime({
    targets: content,
    loop: false,
    easing: 'cubicBezier(0,.5,.5,1)',
    duration: 200,
    opacity: '0',
    height: '0',
    complete: function(anim) {
      anim.animatables.forEach(function(elm){
        elm.target.classList.add('collapsed')
      })
    }
  });
}

export function animeExpand(content) {
  let targetHeight = content.scrollHeight;
  anime({
    targets: content,
    loop: false,
    easing: 'cubicBezier(0,.5,.5,1)',
    duration: 200,
    opacity: '1',
    height: targetHeight,
    begin: function(anim) {
      anim.animatables.forEach(function(elm){
        elm.target.classList.remove('collapsed')
      })
    }
  });
}