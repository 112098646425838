import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    modalId: String,
    imgSource: String
  }

  connect() {
    $(this.modalIdValue).on('show.bs.modal', () => {
      this.swapImage(this.imgSourceValue)
    });

    $(this.modalIdValue).on('hidden.bs.modal', () => {
      this.swapImage('');
    });
  }

  disconnect() {
    $(this.modalIdValue).off('show.bs.modal');
    $(this.modalIdValue).off('hidden.bs.modal');
  }

  showModal() {
    $(this.modalIdValue).modal(
      {
        show: true,
        backdrop: 'static',
        keyboard: false
      }
    )
  }

  swapImage(imgSource = '') {
    $(this.modalIdValue).find('img').attr('src', imgSource)
  }
}
