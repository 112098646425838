import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  setInputValue(e) {
    const elements = Array.prototype.slice.call(document.querySelectorAll('.js-return-to'))
    elements.forEach(el => {
      if (el.hasAttribute('value')) {
        el.value = this.data.get('path')
      } else {
        el.href = el.href + '?return_to=' + this.data.get('path')
      }
    })
  }
}
