import { Controller } from "@hotwired/stimulus"
import { setHTML, changeClass, setValue } from "../helpers/utilities"

export default class extends Controller {

  static targets = [
    "weightDisplay",
    "sizeDisplay",
    "ounceDisplay",
    'sizeUnitInput',
    'weightUnitInput'
  ]

  connect() {
    this.updateAllDisplay()
  }

  set system(value) {
    this.data.set("system", value)
  }

  get system() {
    return this.data.get('system')
  }

  toggleSystem(event) {
    this.clearValues()
    this.system = event.target.value
    this.updateAllDisplay()
  }

  updateAllDisplay() {
    if (this.system == 'metric') {
      this.updateUnitInputs('cm', 'kg')
      this.setDisplay(
        "KG",
        "(Centimeters)",
        "weight-metric", "add"
      )
    } else {
      this.updateUnitInputs('in', 'lb')
      this.setDisplay(
        "LB",
        "(Inches)",
        "weight-metric", "remove"
      )
    }
  }

  setDisplay(weightLabel, sizeLabel, ounceClass, classAction) {
    setHTML(this.weightDisplayTargets, weightLabel)
    setHTML(this.sizeDisplayTargets, sizeLabel)
    changeClass(this.ounceDisplayTargets, ounceClass, classAction)
  }

  updateUnitInputs(sizeUnit, weightUnit) {
    this.sizeUnitInputTargets.forEach(el => el.value = sizeUnit)
    this.weightUnitInputTargets.forEach(el => el.value = weightUnit)
  }

  allInputsToClear() {
    return this.element.querySelectorAll('input[type="text"]:not([name*="declared_value"][name*="_weight"])')
  }

  weightValueInputs() {
    return this.element.querySelectorAll('input[type="text"][name*="_weight"]')
  }

  clearValues() {
    let inputs = this.allInputsToClear()
    let weightValueInputs = this.weightValueInputs()
    setValue(inputs, '')
    setValue(weightValueInputs, '0')
  }

}
