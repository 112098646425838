import { Controller } from "@hotwired/stimulus"
import dropin from "braintree-web-drop-in"
import Rails from '@rails/ujs'
var isSubmitting = false
export default class extends Controller {

  static targets = [
    "payButton",
    "dropinContainer",
    "form",
    "requiredContainer",
    "nonce",
    "deviceData"
  ]

  connect() {
    this.element['shipmentsPayments'] = this
    this.initBraintree()
  }

  get tokenizationKey() {
    return this.data.get('token')
  }

  get clientToken() {
    return this.data.get('client-token')
  }

  get price() {
    return this.data.get('price')
  }

  get currency() {
    return this.data.get('currency') || "USD"
  }

  get provider() {
    return this.data.get('provider')
  }

  get paypalParams() {
    if (this.provider === 'usps' || this.currency == 'AED') return {}

    return {
      paypal: {
        flow: 'vault',
        amount: this.price,
        currency: this.currency,
        buttonStyle: {
          color: 'gold',
          shape: 'rect',
          size: 'medium',
          label: 'pay',
          tagline: false
        },
        locale: this.locale
      }
    }
  }

  get translationParam() {
    if (this.locale.includes('es')) {
      return {
        translations: {
          expirationDateLabel: 'vencimiento'
        }
      }
    } else if (this.locale.includes('fr')) {
      return {
        translations: {
          cvvLabel: 'CVV'
        }
      }
    } else {
      return {}
    }
  }

  // All supported Locales at
  // https://github.com/braintree/braintree-web-drop-in/tree/master/src/translations
  get locale() {
    return window.locale || 'en_US'
  }

  initBraintree() {
    let paymentContainer = this.dropinContainerTarget;
    let tokenizationKey = this.tokenizationKey
    let clientToken = this.clientToken
    let token = clientToken || tokenizationKey

    // We need to change the submit button text back to the initial state
    // if rails is processing a view render from a previous form submission.
    this.payButtonTarget.value = this.payButtonTarget.dataset.text

    dropin.create({
      authorization: token,
      container: paymentContainer,
      vaultManager: false,
      vaultCard: true,
      dataCollector: true,
      locale: this.locale,
      ...this.translationParam,
      ...this.paypalParams
    }, (err, dropinInstance) => {
      if (err) { console.error(err); return }

      this.initSubmission(dropinInstance);
      this.initButton(dropinInstance);
    });
  }

  initSubmission(dropinInstance) {
    this.dropinInstance = dropinInstance;

    this.paymentSubmission = this.paymentSubmission || this.submitPayment.bind(this);
    this.payButtonTarget.addEventListener('click', this.paymentSubmission);
  }

  initButton(dropinInstance) {
    if (dropinInstance.isPaymentMethodRequestable()) {
      // This will be true if you generated the client token
      // with a customer ID and there is a saved payment method
      // available to tokenize with that customer.
      let val = this.requiredFieldsInvalid()
      this.toggleSubmit(val);
    }

    dropinInstance.on('paymentMethodRequestable', (event) => {
      // A customer has selected a payment method.
      // or finished filling out the new cc form
      let val = this.requiredFieldsInvalid()
      this.toggleSubmit(val);
    });

    dropinInstance.on('noPaymentMethodRequestable', () => {
      this.toggleSubmit(true)
    });

    dropinInstance.on('paymentOptionSelected', () => {
      $('.error-replacement-container').html('')
    });
  }

  submitPayment(event) {
    event.preventDefault();
    this.dropinInstance.requestPaymentMethod((err, payload) => {
      if (err) {
        console.error(err);
        // alert(err)
        return
      }
      this.nonceTarget.value = payload.nonce;
      this.deviceDataTarget.value = payload.deviceData;
      this.payButtonTarget.classList.add('disabled')
      this.payButtonTarget.disabled = true
      this.payButtonTarget.value = this.payButtonTarget.dataset.processingText
      if (/summary/.test(window.location.pathname)) {
        Rails.fire(this.formTarget, 'submit')
      } else {
        this.formTarget.submit()
      }
    });
  }

  toggleSubmit(value) {
    this.payButtonTarget.classList.toggle('disabled', value)
    this.payButtonTarget.disabled = value
  }

  resetBraintree() {
    this.dropinInstance.teardown(
      this.initBraintree()
    );
  }

  requiredFieldsInvalid() {
    let requiredContainer = this.requiredContainerTarget
    return requiredContainer &&
            !!requiredContainer.dataset['forms-RequiredValid'] &&
            requiredContainer.dataset['forms-RequiredValid'] == 'false'
  }

}
