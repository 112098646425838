export function classToggleForIE(element, cssClass, condition) {
  if (!window.document.documentMode) {
    element.classList.toggle(cssClass, condition);
  } else {
    var classes = element.className.split(" ");
    var i = classes.indexOf(cssClass);
    if (i >= 0 && !condition) {
      classes.splice(i, 1);
      element.className = classes.join(" ");
    } else if (i < 0 && condition) {
      classes.push(cssClass);
      element.className = classes.join(" ");
    }
  }
}

export function checkIfIE() {
  return window.navigator.userAgent.indexOf("MSIE ") > 0 || !!/Trident.*rv\:11\./.test(navigator.userAgent)
}
