import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

const selectedCountryAbbreviation = (selectEl) => {
  if(selectEl) {
    let opt = Array.prototype.slice.call(selectEl.options)[selectEl.selectedIndex]
    return opt.dataset.abbreviation || ''
  }
}

export default class extends Controller {

  static targets = [ "loqateInput", "manualInput", 'toggleManual', 'toggleAuto' ]

  initialize() {
    this.isManualInput = this.data.get('manual-input')
    this.loqateAddressInput = this.loqateInputTarget.querySelector('input[type="text"]')
  }

  connect() {
    this.manualInputTarget.hidden = !this.isManualInput
    this.toggleAutoTarget.hidden  = !this.isManualInput

    this.requiredInputs = this.manualInputTarget.querySelectorAll('[required]')
    this.isManualVisible()
    this.toggleCommodityTypeInputs()
  }

  toggleInputVisibilities() {
    if (this.isManualTargetDisabled()) {
      return false
    } else {
      this.loqateInputTarget.hidden  = this.isManualInput
      this.toggleManualTarget.hidden = this.isManualInput

      this.manualInputTarget.hidden = !this.isManualInput
      this.toggleAutoTarget.hidden  = !this.isManualInput

      this.isManualVisible()
    }
  }

  isManualTargetDisabled() {
    return this.toggleManualTarget.classList.contains('disabled')
  }

  switchInputMethod(e) {
    e.preventDefault()
    this.isManualInput = !this.isManualInput
    this.toggleInputVisibilities()
    window.dispatchEvent(new Event('resize')) // Trigger a resize event to ensure tooltips are properly positioned
  }

  isManualVisible() {
    if (this.manualInputTarget.hidden == false) {
      this.loqateAddressInput.removeAttribute('required')
      this.requiredInputs.forEach(function(el) { el.setAttribute('required', 'required') })
    } else {
      this.loqateAddressInput.setAttribute('required', 'required')
      this.requiredInputs.forEach(function(el) { el.removeAttribute('required') })

    }
  }

  toggleCommodityTypeInputs(){
    // Show the commodity type input only if the origin and destination countries are the same
    //
    // Inputs are generated in different files, so it's easier to get them using standard JavaScript instead of via Stimulus targets
    let originAddressInput = document.querySelector('#rate_request_origin_attributes_address_attributes_country_id')
    let destinationAddressInput = document.querySelector('#rate_request_destination_attributes_address_attributes_country_id')
    let commodityTypeInputs = document.querySelector('#commodity-type-inputs')
    let commodityTypeInputRadios = document.querySelectorAll('#commodity-type-inputs input')

    const originAbbreviation = selectedCountryAbbreviation(originAddressInput)
    const destAbbreviation = selectedCountryAbbreviation(destinationAddressInput)

    if(commodityTypeInputs) {
      if( originAbbreviation === 'US' && destAbbreviation === 'US' ) {
        commodityTypeInputs.classList.add('hidden'); // Hide the commodity inputs
        for( var i=0; i < commodityTypeInputRadios.length; i++ ) {
          commodityTypeInputRadios[i].required = false // Make the commodity inputs not required
        }

      } else {
        commodityTypeInputs.classList.remove('hidden') // Un-hide the commodity inputs
        for( var i=0; i < commodityTypeInputRadios.length; i++ ) {
          commodityTypeInputRadios[i].required = true // Make the commodity inputs required
        }
      }
    }
  }

}