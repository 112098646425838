import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "form" ]

  connect() {
    this.initModal()
  }

  get modalId() {
    return this.data.get('modal-id')
  }

  initModal() {
    let modal = this.findModal();

    $(this.formTarget).on('ajax:success', function(event, data) {
      $(modal).modal('hide');
    });
  }

  findModal() {
    return document.getElementById(this.modalId)
  }

}