import { Controller } from "@hotwired/stimulus"
import { scrollReset } from "../helpers/utilities"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {

  static targets = ['activeSection']

  connect() {
    // force browser to start from the top each load. reduce bounce.
    scrollReset()
    this.scrollToActive()
  }

  scrollToActive() {
    let bodyTop = document.querySelector(".main-content").getBoundingClientRect().top
    let sectionTop =  document.querySelector("[data-shipments--scroll-target='activeSection']").getBoundingClientRect().top
    let scrollPos = sectionTop - bodyTop + 200// 200 is just an arbitrary number. the section headers were 117 each so I started from there and went up
    anime({
      targets: [document.documentElement, document.body],
      scrollTop: scrollPos,
      easing: 'easeInOutSine',
      duration: 1000,
    });
  }

}