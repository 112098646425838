import { Controller } from "@hotwired/stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {

  static targets = [ 'toggleInput', 'inputField', 'link' ]

  connect() {
    this.initializeToggle()
  }

  toggleField(e) {
    e.preventDefault()
    this.hideLinkTarget()
    anime({
      targets: this.toggleInputTarget,
      opacity: 1,
      delay: 100,
      easing: 'easeInOutSine',
      duration: 250,
      begin: function(anim) {
        anim.animatables.forEach(function(elm){
          elm.target.classList.remove('d-none')
        })
      }
    });
  }

  initializeToggle() {
    if ([null, ''].includes(this.inputFieldTarget.value)) {
      this.toggleInputTarget.classList.add('d-none')
      this.toggleInputTarget.style.opacity = 0
    } else {
      this.hideLinkTarget()
    }
  }

  hideLinkTarget() {
    this.linkTarget.classList.add('d-none')
  }

}