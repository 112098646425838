import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = [ "form" ]

  connect() {
    if (this.justSaved == 'true') {
      this.confirm()
    }
  }

  get shipmentId() {
    return this.data.get('shipment-id')
  }

  get modalId() {
    return this.data.get('modal-id')
  }

  get justSaved() {
    return this.data.get('just-saved')
  }

  save() {
    this.makeFormRemote(true)
    if (this.shipmentId) {
      this.update()
    }else {
      this.create()
    }
  }

  update() {
    Rails.fire(this.formTarget, 'submit')
    this.confirm()
    this.makeFormRemote(false)
  }

  create() {
    Rails.fire(this.formTarget, 'submit')
  }

  confirm() {
    $(`#${this.modalId}`).modal()
  }

  makeFormRemote(val = true) {
    this.formTarget.dataset.remote = val
  }

}
