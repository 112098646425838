import { Controller } from "@hotwired/stimulus"
import { parseBrackets } from "../helpers/utilities"
import { animeOut, animeIn} from "./anime_actions"

export default class extends Controller {

  static targets = [ "" ]

  filter() {
    let inputName = event.currentTarget.name
    let checked = event.currentTarget.checked
    this.toggleCarrierColumns(inputName, checked)
  }

  toggleCarrierColumns(carrier, checked) {
    let carrierName = parseBrackets(carrier).toLowerCase()
    let selector = '[data-carrier="' + carrierName + '"]'
    let content = document.querySelectorAll(selector)
    let action = this.getActionFromCheckbox(checked)
    this.animateContent(content, action)
  }

  getActionFromCheckbox(ifChecked) {
    return ifChecked == true ? 'add' : 'remove'
  }

  animateContent(content, action) {
    let event = new Event('filterCards')
    if (action == 'remove') {
      animeOut(content, event)
    } else {
      animeIn(content, event)
    }
  }


}
