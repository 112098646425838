export function setHTML(targets, HTML) {
  targets.forEach(function(el) { el.innerHTML = HTML })
}

export function changeClass(targets, className, action) {
  targets.forEach(function(el) { el.classList[action](className) })
}

export function setValue(targets, value) {
  if (targets instanceof NodeList) {
    targets.forEach(target => {
      target.value = value
    })
  } else {
    targets.value = value
  }
}

export function disableSubmit(buttonTarget) {
  buttonTarget.classList.add('disabled')
  buttonTarget.setAttribute('disabled', true)
}

export function enableSubmit(buttonTarget) {
  buttonTarget.classList.remove('disabled')
  buttonTarget.removeAttribute('disabled')
}

export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

export function parseBrackets(inputName) {
  let group = inputName.match(/\[(.*?)\]/)
  return group[1]
}

export function checkNotEqual(inputTargets) {
  return inputTargets[0].value != inputTargets[1].value
}

export function checkForEmpty(element) {
  return element.value != ''
}

export function scrollReset() {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  } else {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }
}