import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "defaultAddressTemplate",
    "newAddressTemplate",
    'billingAddressToggle',
    'inputContainer',
    'dropdown'
  ]


  connect() {
    this.toggleBillingAddressInputs()
  }

  get addressFieldTargetType() {
    return this.billingAddressToggleTarget.checked == true && 'default' || 'new'
  }
  toggleBillingAddressInputs() {
    const addressFieldTarget = this.addressFieldTargetType + 'AddressTemplateTarget'
    this.inputContainerTarget.innerHTML = this[addressFieldTarget].innerHTML
    this.triggerSwapEvent()
  }

  triggerSwapEvent() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("required-form-swapped", true, true, null)
    window.dispatchEvent(event)
  }

  toggleDropdown() {
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.toggle('hidden')
    }
  }


}
