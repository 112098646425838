import { Controller } from "@hotwired/stimulus"
import { checkNotEqual } from "../helpers/utilities"

export default class extends Controller {

  static targets = [
    "passwordField", "passwordInput",
    "revealPassword", "matchError",
  ]

  initialize(){
    this.passwordRevealed = 'false'
  }

  matchPasswords() {
    if(this.passwordInputTargets.every(this.checkForEmpty)){
      let matchInvalid = checkNotEqual(this.passwordInputTargets)
      this.passwordFieldTargets.forEach((el, i) => {
        el.classList.toggle('is-invalid-mismatch', matchInvalid)
      })
      this.matchErrorTarget.classList.toggle('show', matchInvalid)
    }
  }

  checkForEmpty(element) {return element.value != ''}

  get passwordRevealed() {
    return this.data.get('passwordRevealed')
  }

  set passwordRevealed(value) {
    this.data.set('passwordRevealed', value)
  }

  revealPassword() {
    let passwordFieldType = this.passwordRevealed == 'true' ? 'password' : 'text'
    let passwordFieldText = this.passwordRevealed == 'true' ? 'show password' : 'hide password'
    this.passwordInputTargets.forEach((el, i) => {
      el.type = passwordFieldType
    })
    this.revealPasswordTargets.forEach((el, i) => {
      el.title = passwordFieldText
    })
    this.passwordRevealed = this.passwordRevealed == 'true' ? 'false' : 'true'
  }

}
