import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "container", "content" ]

  connect() {
    this.setOffset()

  }

  setOffset() {
    const windowPadding = 30 // How close a tooltip can get to the edge of the window
    let windowWidth = document.documentElement.clientWidth // Width of the window
    let containerLeft = this.containerTarget.getBoundingClientRect().left // X coordinate of the container
    let contentWidth = this.contentTarget.getBoundingClientRect().width // Width of the tooltip content

    let edgeOverlap = Math.max(0, (containerLeft + contentWidth) - (windowWidth - windowPadding)) // How much the tooltip extends past the boundary when no transformations are applied

    this.contentTarget.style.transform = "translateX(-" + edgeOverlap + "px)" // Keep tooltips from overflowing the window
  }


}