import { Controller } from "@hotwired/stimulus"
import { set } from "date-fns"

export default class extends Controller {

  static targets = [
    'calculateButton',
  ]

  static values = {
    calculateUrl: String,
    modalId: String,
  }

  connect() {
    $(this.modalIdValue).modal('hide')
  }

  swapFormUrl(e) {
    e.preventDefault()
    let form = this.element
    form.action = this.calculateUrlValue
    form.dataset.turbo = 'true'

    // Firing the modal here and adding a timeout to make sure
    // the loader shows for the complete animation.
    if (form.checkValidity()) {
      $(this.modalIdValue).modal('show')

      setTimeout(() => {
        form.requestSubmit()
      }, 5000)
    } else {
      form.requestSubmit()
    }

  }

}
