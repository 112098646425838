import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [ "url", "pickupDate", "availableAt", "availableUntil" ]
  static values = {
    url: String
  }

  pickupDateChange(e) {
    let data = {
      target: this.availableAtTarget.id,
      pickup_date: this.pickupDateTarget.selectedOptions[0].value,
      time_type: 'available_at'
    }

    this.sendGetRequest(data)
  }

  availableAtChange(e) {
    let data = {
      target: this.availableUntilTarget.id,
      pickup_date: this.pickupDateTarget.selectedOptions[0].value,
      available_at: this.availableAtTarget.selectedOptions[0].value,
      time_type: 'available_until'
    }

    this.sendGetRequest(data)
  }

  sendGetRequest(data) {
    let params = new URLSearchParams(data)

    get(`${this.urlValue}/?${params}`, {
      responseKind: 'turbo-stream'
    })
  }

}