import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask"

const maskConfig = {
  currency: {
    alias: 'currency',
    groupSeparator: ',',
    placeholder: '0',
    autoUnmask: true,
    removeMaskOnSubmit: true,
    onBeforeMask: function (value, opts) {
      const numVal = Number(value)
      // I don't think we need this conditional checking the includes('.') since
      // declared value has a controller of its own to handle masking.
      // I am leaving here since I am not sure on the impact of removing it.
      if (typeof value !== 'undefined' && value.includes('.')) return value

      return String(numVal / 100)
    },
    onUnMask: function(maskedValue, unmaskedValue) {
      let cleanedMaskedValue = maskedValue.replace(/[^\d.-]/g, '');
      return Number(cleanedMaskedValue) * 100;
    }
  },
  aes: {
    mask: '99{14}',
    alias: 'aes',
    placeholder: 'X',
    static: true,
    allowMinus: false,
    autoUnmask: true,
    removeMaskOnSubmit: true
  }
}

const getConfigFor = (configName) => maskConfig[configName] || {}

export default class extends Controller {
  connect() {
    const config = getConfigFor(this.data.get('config'))
    Inputmask(config).mask(this.element)
  }
}
