import { Controller } from "@hotwired/stimulus"
import { animeCollapse, animeExpand } from "./anime_actions"

export default class extends Controller {

  static targets = [ "toggle", "options" ]

  connect() {
    animeCollapse(this.optionsTarget);
  }

  toggleRateOptions() {
    var currentLabel = this.toggleTarget.textContent;
    if ( this.optionsTarget.classList.contains('collapsed') ){
      this.element.classList.add('options-open')
      this.toggleTarget.innerHTML = this.toggleTarget.getAttribute("data-toggle-text");
      animeExpand(this.optionsTarget);
    } else {
      this.element.classList.remove('options-open')
      this.toggleTarget.innerHTML = this.toggleTarget.getAttribute("data-toggle-text");
      animeCollapse(this.optionsTarget);
    }
    this.toggleTarget.setAttribute("data-toggle-text", currentLabel);

  }



}
