import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';
import { set } from "date-fns";

export default class extends Controller {

  static values = {
    specialServices: Array,
    rateId: Number,
    modalId: String
  }

  updateRate(e) {
    // We are using Rails here so that we can use the update.js.erb view as the response.
    // Rails.ajax only takes data as a string, so we need to convert the JSON version
    // of our data to a paramatized string format.
    let params = `rate_request[${e.target.name}]=${e.target.checked}`
    this.specialServicesValue.forEach((service) => {
      params += `&special_services[]=${service}`
    })

    return Rails.ajax({
      url: `/freight/rates/${this.rateIdValue}`,
      type: 'PUT',
      data: params,
      complete: (resp)=> {
        // Timeout used to allow animation to run once completly before hiding
        setTimeout(() => {
          this.toggleModal(
            {
              show: false
            }
          )
        }, 1500)
      }
    });
  }

  toggleModal(toggleType) {
    $(this.modalIdValue).modal(toggleType)
  }
}
