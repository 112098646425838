import { Controller } from "@hotwired/stimulus"
import Pikaday from "pikaday/pikaday.js"
import { format } from "date-fns"

export default class extends Controller {

  static targets = [ "datepicker", "dateHiddenInput" ]

  static values = {
    startDateString: String
  }

  connect() {
    this.startDate = new Date(this.startDateStringValue + 'T00:00') // Adding T00:00 to make sure it sets date based on tz
    this.initDatePicker()
  }

  initDatePicker() {

    let _this = this;
    let threeDaysFromStart = new Date(new Date().setDate(this.startDate.getDate() + 3));

    const picker = new Pikaday({
      field: this.datepickerTarget,
      format: 'YYYY-MM-DD',
      position: 'bottom right',
      defaultDate: this.startDate,
      minDate: this.startDate,
      maxDate: threeDaysFromStart,
      disableDayFn: function(e){
        let findSunday = new Date(e)
        if (findSunday.getDay() == 0) return true
      },
      onSelect: function(date) {
        _this.dateHiddenInputTarget.value = date
        _this.datepickerTarget.value = format(date, 'E., MMMM d')
        _this.submitShipDate()
      }
    });
  }

  submitShipDate() {
    let form = document.getElementById("select-shipping")
    form.submit()
  }


}
