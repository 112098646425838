import { Controller } from "@hotwired/stimulus"
import { scrollReset } from "./helpers/utilities"

export default class extends Controller {

  connect() {
    // stop browser from restoring from last scroll position.
    scrollReset()
  }

}
