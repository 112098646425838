import { Controller } from "@hotwired/stimulus"
import { setValue } from "../helpers/utilities"

const kgToLb = (kg) =>  kg * 2.20462

export default class extends Controller {

  static targets = [ "primaryWeight", "extraWeight", 'warningCopy' ]

  connect() {
  }

  checkWeight() {
    if (this.haswarningCopyTarget){
      const total = this.calculateImperialTotal()
      const classListFunc = (total > 70.0 && total < 151) ? 'remove' : 'add'
      this.warningCopyTarget.classList[classListFunc]('d-none')
    }
  }

  calculateImperialTotal() {
    return this.weightValueArray.reduce((sum, newVal) => sum += newVal)
  }


  get currentMeasurementSystem() {
    return document.querySelector("[data-start--dimensions-target='systemInput']:checked").value
  }

  get isCurrentlyImperial() {
    return this.currentMeasurementSystem === 'imperial'
  }

  get weightValueArray() {
    const values = [
      this.primaryWeightTarget,
      this.extraWeightTarget
    ].map(inp => this.imperialValue(inp))

    !this.isCurrentlyImperial && values.pop()

    return values
  }

  imperialValue(input) {
    const numVal = Number(input.value)
    if (this.isCurrentlyImperial) return numVal

    return kgToLb(numVal)
  }

}
