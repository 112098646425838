import { Controller } from "@hotwired/stimulus"
import jquery from "jquery"

export default class extends Controller {

  static targets = []

  // Add a temporary "saved" indicator to an input label
  markInputAsSaved(e) {
    let labelElem = $(e.target).parent().find('label'); // Find the label that's a sibling of the input
    labelElem.find('span').remove(); // Prevent duplicate elements
    labelElem.append('<span class="pl-2 color-green text-small">SAVED!</span>'); // Create the alert
    labelElem.find('span').delay(500).fadeOut(500); // Wait a bit then fade out and remove the alert
  }

}
