import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["select", "currencyCode", "declaredValueInput"]

  change() {
    const target = this.selectTarget
    const currencyCode = target.options[target.selectedIndex].innerText.split(" - ")[0]
    this.currencyCodeTargets.forEach(span => span.innerHTML = currencyCode)
    document.querySelectorAll('[data-start--validations-currency-id]').forEach( el => {
      el.setAttribute('data-start--validations-currency-id', target.value)
    })
    this.triggerChangeForInputs()
  }

  updateAllDisplay() {
    this.change()
  }

  triggerChangeForInputs() {
    // Send change events to all declared value inputs to run validations
    this.declaredValueInputTargets.forEach( el => {
      let event = new Event('input')
      el.dispatchEvent(event)
    })
  }

}

