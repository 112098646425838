import { Controller } from "@hotwired/stimulus"
import RequestPoller from '../../src/poller'
import lottie from 'lottie-web'

export default class extends Controller {

  static targets = [ "animation" ]

  listen() {
    this.poll.subscribe((data) => {
      if(this.canResolve(data)) {
        this.poll.stop()
        Turbo.visit(data.record.resolved_path, { action: 'replace' })
      }

      // uncomment to get errors from api to show on the loading screen
      // this.checkForErrors(data)
    })
  }

  checkForErrors(data) {
    if (typeof data.record.errors !== 'undefined' && data.record.errors.length > 0) {
      let text = data.record.errors[0].provider
                                      .split(/[^a-z|A-Z]/gi)
                                      .map(wrd => wrd[0].toUpperCase() + wrd.slice(1,wrd.length))
                                      .join('')

      text += ": "
      text += data.record.errors[0].message
      let alert = document.querySelector('.alert')
      alert.hidden = false
      alert.innerText = text
    }
  }

  connect() {
    const url = this.element.dataset.pollUrl
    this.poll = new RequestPoller(url, {}, 3500)
    this.listen()
    if (this.hasAnimationTarget) {
      this.loadingAnimation()
    }
    this.setRequestTimeout()
  }


  loadingAnimation() {
    lottie.loadAnimation({
      container: this.animationTarget,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.element.dataset.animPath,
    });
  }

  setRequestTimeout() {
    this.timedOut = false
    let seconds = this.element.dataset.timeout
    if(seconds) {
      setTimeout(
        () => {
          this.timedOut = true
        }, Number(seconds)
      );
    }
  }

  canResolve(data) {
    return (data.success && data.record.resolved) || this.timedOut
  }

}
