import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["select"]

  change(event) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("locale", event.target.value)
    let url = `${window.location.pathname}?${urlParams.toString()}`
    Turbo.visit(url);
  }

}

