import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['flash']

  connect() {
    this.closeFlash()
  }

  closeFlash() {
    setTimeout( () => {
      $(this.flashTarget).alert('close');
    }, 10*1000);
  }
}
